import Vue from "vue";
import Vuex from "vuex";

import { getField, updateField } from "vuex-map-fields";
// import createPersistedState from "vuex-persistedstate";

import settingsService from "@/services/settings";
import marksService from "@/services/marks";
import tempCarsService from "@/services/tempCars";

//import settingsMock from "@/mock/settings.json";
//import marksMock from "@/mock/marks.json";
//import tempCarsMock from "@/mock/tempCars.json";

Vue.use(Vuex);

const emptyCar = {
  mark: null,
  model: null,
  overallDimension: null,
  vcq: { color: "FFFFFF", quantity: 1, variant: null },
  filmId: 1,
  views: null,
};

export default new Vuex.Store({
  // plugins: [createPersistedState()],
  state: {
    settings: {},
    customPalette: [],
    customPaletteBg: [],
    customPaletteBgIndx: null,
    bgGradientPointIndx: null,
    marks: [],
    tempCars: {},
    cars: [{ ...emptyCar }],
    carIndx: 0,
    design: null,
    isFilmForAll: true,
    viewId: "left",
    carIndxDesignApplied: null,
    isFObjAdded: false,
    isFigsEditable: true,
    fObjSelectedId: null,
    toolbar: {
      text: {
        fill: "#ce4a2c",
        fontFamily: "Arial",
        fontSize: 40,
        fontWeight: "normal",
        fontStyle: "normal",
        text: "Ваш текст на авто",
        underline: false,
        linethrough: false,
      },
      image: {
        url: "",
      },
      figure: {
        fill: "#ce4a2c",
        stroke: "#ce4a2c",
      },
      sticker: {
        fill: "#ce4a2c",
      },
    },
  },
  getters: {
    getField,
    getCarField(state) {
      return getField(state.cars[state.carIndx]);
    },
    marks: (state) => state.marks,
    customPalette: (state) => state.customPalette,
    totalTypes: (state) => state.cars.length,
    totalCars: (state) =>
      state.cars.reduce((a, c) => (a += c.vcq?.quantity || 0), 0),
    isIssetCar: (state) => {
      const car = state.cars[0] || {};
      return !(!car.model?.id || !car.mark?.id || !car.overallDimension?.id) || (car.mark?.id === 0 && car.model?.id <= 0);
    },
  },
  mutations: {
    updateField,
    updateCarField(state, field) {
      updateField(state.cars[state.carIndx], field);
    },
    updateAllCarsFilmId(state, filmId) {
      state.cars.forEach((c) => (c.filmId = filmId));
    },
    addCarRow(state) {
      if (!state.cars[state.cars.length - 1].vcq.variant) return;
      state.cars.push({ ...emptyCar });
      state.carIndx = state.cars.length - 1;
    },
    removeCarRow(state, indx) {
      state.cars.length > 1
        ? state.cars.splice(indx, 1)
        : state.cars.splice(indx, 1, { ...emptyCar });
      if (indx <= state.carIndx && state.carIndx > 0) state.carIndx--;
    },
    clearCars(state) {
      state.cars = [...state.cars.filter((c) => c.vcq.variant)];
      state.carIndx = 0;
    },
    pushColor: (state, color) => {
      if (state.customPalette.length >= 7) {
        state.customPalette.shift();
      }
      state.customPalette.push(color);
    },
    nextCar: (state) =>
      (state.carIndx = (state.carIndx + 1) % state.cars.length),
    prevCar: (state) =>
      (state.carIndx =
        Math.abs(-state.cars.length - state.carIndx - 1) % state.cars.length),
    setSettings: (state, settings) => (state.settings = settings),
    setMarks: (state, marks) => (state.marks = marks),
    setTempCars: (state, tempCars) => (state.tempCars = tempCars),
  },
  actions: {
    async featchSettings({ commit }) {
      try {
        const settings = (await settingsService.getSettings()).data.result;
        // const settings = settingsMock;
        commit("setSettings", settings);
      } catch (e) {
        console.error(e);
      }
    },
    async featchMarks({ commit }) {
      try {
        const marks = (await marksService.getMarks()).data.result;
        // const marks = marksMock;
        commit("setMarks", marks);
      } catch (e) {
        console.error(e);
      }
    },
    async featchTempCars({ commit }) {
      try {
        const tempCars = (await tempCarsService.getTempCars()).data.result;
        //const tempCars = tempCarsMock;
        await new Promise((_) => setTimeout(_, 500));

        commit("setTempCars", tempCars);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
