const ENV = process.env.NODE_ENV
  ? process.env.NODE_ENV.toUpperCase()
  : "PRODUCTION";

let BASE_URL, API_URL;

if (ENV === "DEVELOPMENT") {
  BASE_URL = "https://api.cardis.tech";
  API_URL = `${BASE_URL}/api`;
}

if (ENV === "PRODUCTION") {
  BASE_URL = "https://api.cardis.tech/";
  API_URL = `${BASE_URL}/api`;
}

const config = {
  BASE_URL,
  API_URL,
  VERSION: "0.1.1",
};

export default config;
